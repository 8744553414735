// Polyfills

/**
 * Uncomment parts of ES6 which are necessary for your project or your browser support requirements.
 */

import'core-js/es6';
import'core-js/es6/symbol';
import'core-js/es6/object';
import'core-js/es6/function';
import'core-js/es6/parse-int';
import'core-js/es6/parse-float';
import'core-js/es6/number';
import'core-js/es6/math';
import'core-js/es6/string';
import'core-js/es6/date';
import'core-js/es6/array';
import'core-js/es6/regexp';
import'core-js/es6/map';
import'core-js/es6/set';
import'core-js/es6/weak-map';
import'core-js/es6/weak-set';
import'core-js/es6/typed';
import'core-js/es6/reflect';
import'core-js/es6/promise';

import 'core-js/es7/reflect';

import 'zone.js/dist/zone';
import 'zone.js/dist/long-stack-trace-zone';

import 'classlist.js';

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
import 'web-animations-js';  // Run `npm install --save web-animations-js`.
